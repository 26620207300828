<!--The content below is only a placeholder and can be replaced.-->
<div style="border: 3px dashed green; height: auto;">
  <div style="text-align:center">
    <h1>
      Welcome to {{ title }}!
    </h1>
  </div>
  
  <main>
      <!-- <div style="display: flex; justify-content: space-between;">
        <ul>
          <li><h2><a routerLink="/child1">Thời trang phụ nữ</a></h2></li>
          <li><h2><a routerLink="/child2">Thời trang nam</a></h2></li>
        </ul>
        <div>hiện đang có 10 sản phẩm trong giỏ hàng</div>
      </div> -->
      <div style="border: 3px dashed blue; margin-bottom: 30px;"><div id="reactTest"></div></div>
      <section class="child-app" style="margin-bottom: 30px;">
          <router-outlet></router-outlet>
      </section>
  </main>
</div>


